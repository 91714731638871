import React from 'react';

export function PushPinSvg({
  className,
  filled = false,
}: {
  className?: string;
  filled?: boolean;
}) {
  return filled ? (
    <svg
      height="200px"
      width="200px"
      version="1.1"
      id="_x32_"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="#000000"
      transform="matrix(-1, 0, 0, 1, 0, 0)"
      stroke="#000000"
      className={className}
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <style type="text/css"> </style>
        <g>
          <polygon points="444.241,119.211 394.039,69.017 326.28,1.25 294.904,32.625 307.453,72.778 156.862,190.74 84.7,165.894 40.196,206.515 173.475,339.784 306.744,473.061 347.355,428.55 322.518,356.388 440.471,205.797 480.624,218.354 512,186.978 "></polygon>{' '}
          <polygon points="18.828,454.277 0,510.75 56.464,491.914 179.451,368.937 141.805,331.291 "></polygon>{' '}
        </g>
      </g>
    </svg>
  ) : (
    <svg
      fill="currentColor"
      id="push-pin"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53.011 53.011"
      xmlSpace="preserve"
      stroke="currentColor"
      strokeWidth="2.014418"
      className={className}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#CCCCCC"
        strokeWidth="0.5301100000000001"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M52.963,21.297c-0.068-0.329-0.297-0.603-0.609-0.727c-8.574-3.416-16.173-0.665-18.361,0.288L19.114,8.2 c0.52-4.568-1.944-7.692-2.054-7.828C16.881,0.151,16.618,0.016,16.335,0c-0.282-0.006-0.561,0.091-0.761,0.292L0.32,15.546 c-0.202,0.201-0.308,0.479-0.291,0.765c0.016,0.284,0.153,0.549,0.376,0.726c2.775,2.202,6.349,2.167,7.726,2.055l12.721,14.953 c-0.868,2.23-3.52,10.27-0.307,18.337c0.124,0.313,0.397,0.541,0.727,0.609c0.067,0.014,0.135,0.021,0.202,0.021 c0.263,0,0.518-0.104,0.707-0.293l14.57-14.57l13.57,13.57c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293 c0.391-0.391,0.391-1.023,0-1.414l-13.57-13.57l14.527-14.528C52.929,21.969,53.031,21.627,52.963,21.297z M35.314,36.755 L21.89,50.18c-2.437-8.005,0.993-15.827,1.03-15.909c0.158-0.353,0.1-0.765-0.15-1.059L9.31,17.39 c-0.191-0.225-0.473-0.352-0.764-0.352c-0.051,0-0.103,0.004-0.154,0.013c-0.036,0.006-3.173,0.472-5.794-0.955l13.5-13.5 c0.604,1.156,1.391,3.26,0.964,5.848C17.004,8.79,17.133,9.141,17.4,9.368l15.785,13.43c0.309,0.262,0.748,0.309,1.105,0.128 c0.077-0.04,7.378-3.695,15.869-1.017L35.314,36.755z"></path>{' '}
      </g>
    </svg>
  );
}
