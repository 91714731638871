export const Colors = {
  purple: '#3F0E40',
  purple_light: '#795679',
  purple_dark: '#350d36',
  purple_border: '#522653',
  inactive_channel: '#ffffffb3',
  purple_hover: '#4D2A51',
  blue_active: '#1164A3',
  gray_light: '#E2E2E2',
  gray_highlight: '#F5F5F5',
  gray_menu_bg: '#F8F8F8',
  gray_dark: '#626162',
  gray_border: '#BBBABB',
  gray_text: '#4D4C4D',
  blue_selected_bg: '#E8F5FA',
  blue_selected_border: '#1D9BD1',
  blue_link: '#1264A3',
  green_active: '#2AAC76',
  green: '#117A5A',
  red: '#e01e5a',
};
